// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.car-cost-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
.car-cost-form .loss-value-input .native-input {
  max-width: 60%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhci1jb3N0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsU0FBQTtFQUNBLGdCQUFBO0FBQ0Y7QUFFSTtFQUNFLGNBQUE7QUFBTiIsImZpbGUiOiJjYXItY29zdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jYXItY29zdC1mb3JtIHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgZ2FwOiAxMHB4O1xuICBtYXJnaW4tdG9wOiAyMHB4O1xuXG4gIC5sb3NzLXZhbHVlLWlucHV0IHtcbiAgICAubmF0aXZlLWlucHV0IHtcbiAgICAgIG1heC13aWR0aDogNjAlO1xuICAgIH1cbiAgfVxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/pages/maintenance-cost-calculating/car-cost/car-cost.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AACF;AAEI;EACE,cAAA;AAAN;AACA,ojBAAojB","sourcesContent":[".car-cost-form {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  margin-top: 20px;\n\n  .loss-value-input {\n    .native-input {\n      max-width: 60%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
