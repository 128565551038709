import { Component, Input, OnInit } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';

export enum MileageLabelDisplayType {
  Good = 1501,
  Normal = 1500,
  Low = 1000,
  VeryLow = 500,
}

export enum MileageLabelViewTypes {
  Default = 'default',
  Simple = 'simple',
}

@Component({
  selector: 'app-mileage-label',
  standalone: true,
  template: `
    <div class="mileage-label mileage-{{ displayType }}">
      {{
        viewType === MileageLabelViewTypes.Default
          ? 'через ' + (mileage | integerWithSpaces) + ' км'
          : (mileage | integerWithSpaces) + ' км'
      }}
    </div>
  `,
  imports: [PipesModule],
  styleUrls: ['./mileage-label.component.scss'],
})
export class MileageLabelComponent implements OnInit {
  @Input() mileage: number = 1500;
  @Input() viewType: MileageLabelViewTypes = MileageLabelViewTypes.Default;
  readonly MileageLabelViewTypes = MileageLabelViewTypes;

  displayType = MileageLabelDisplayType.Normal;

  constructor() {}

  ngOnInit(): void {
    this.displayType = this.getMileageDisplayType();
  }

  getMileageDisplayType(): MileageLabelDisplayType | null {
    const mileageSize = this.mileage;

    if (mileageSize <= MileageLabelDisplayType.VeryLow) return MileageLabelDisplayType.VeryLow;
    if (mileageSize <= MileageLabelDisplayType.Low) return MileageLabelDisplayType.Low;
    if (mileageSize <= MileageLabelDisplayType.Normal) return MileageLabelDisplayType.Normal;

    if (mileageSize >= MileageLabelDisplayType.Good) return MileageLabelDisplayType.Good;
  }
}
