import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { IPushNotification } from 'src/app/providers/notifications/types';

@Component({
  selector: 'app-push-information-modal',
  standalone: true,
  imports: [IonicModule, TranslateModule, PipesModule],
  templateUrl: './push-information-modal.component.html',
  styleUrls: ['./push-information-modal.component.scss'],
})
export class PushInformationModalComponent {
  @Input() notification: IPushNotification;

  public isOpen: boolean = false;

  constructor() {}
}
