import { Component, Input, OnInit } from '@angular/core';
import { ThemeDetection } from '@awesome-cordova-plugins/theme-detection/ngx';
import { IonicModule } from '@ionic/angular';

export enum PushLabelIconStyles {
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Error = 'error',
}

export enum PushLabelErrors {
  Default = 'default',
  Warn = 'warn',
  Danger = 'danger',
}

@Component({
  selector: 'app-push-label-icon',
  standalone: true,
  imports: [IonicModule],
  template: `
    <ion-icon src="/assets/icons/{{ iconName }}.svg" style="width:100%; height:100%;"></ion-icon>
  `,
})
export class PushLabelIconComponent implements OnInit {
  @Input() iconStyle: PushLabelIconStyles = PushLabelIconStyles.Info;
  @Input() errorStyle: PushLabelErrors;

  iconName: string;

  constructor(private themeDetection: ThemeDetection) {}

  getIconStyle() {
    let iconName = 'info-common';
    switch (this.iconStyle) {
      case PushLabelIconStyles.Info:
        iconName = 'info-common';
        break;
      case PushLabelIconStyles.Warning:
        iconName = 'info-warn';
        break;
      case PushLabelIconStyles.Danger:
        iconName = this.themeDetection.isDarkModeEnabled ? 'info-danger-dark' : 'info-danger';
        break;
      case PushLabelIconStyles.Error:
        iconName = this.getErrorIcon();
        break;
      default:
        iconName = 'info-common';
        break;
    }
    return iconName;
  }
  getErrorIcon() {
    let result = 'check-engine';
    switch (this.errorStyle) {
      case PushLabelErrors.Default:
        result = 'check-engine';
        break;
      case PushLabelErrors.Warn:
        result = 'check-engine-warn';
        break;
      case PushLabelErrors.Danger:
        result = this.themeDetection.isDarkModeEnabled
          ? 'check-engine-danger-dark'
          : 'check-engine-danger';
        break;
      default:
        result = 'check-engine';
        break;
    }
    return result;
  }

  ngOnInit() {
    this.iconName = this.getIconStyle();
  }
}
