// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button {
  --border-radius: 50%;
  width: 40px;
  height: 40px;
}
.back-button .back-icon {
  font-size: 24px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vZGFsLWJhY2stYnV0dG9uLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usb0JBQUE7RUFFQSxXQUFBO0VBQ0EsWUFBQTtBQUFGO0FBRUU7RUFDRSxlQUFBO0FBQUoiLCJmaWxlIjoibW9kYWwtYmFjay1idXR0b24uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYmFjay1idXR0b24ge1xuICAtLWJvcmRlci1yYWRpdXM6IDUwJTtcblxuICB3aWR0aDogNDBweDtcbiAgaGVpZ2h0OiA0MHB4O1xuXG4gIC5iYWNrLWljb24ge1xuICAgIGZvbnQtc2l6ZTogMjRweDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/modal-back-button/modal-back-button.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EAEA,WAAA;EACA,YAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AACA,gfAAgf","sourcesContent":[".back-button {\n  --border-radius: 50%;\n\n  width: 40px;\n  height: 40px;\n\n  .back-icon {\n    font-size: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
