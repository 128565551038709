// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  background-color: transparent;
  display: block;
  height: 110px;
  margin: auto auto 0;
  object-fit: contain;
  width: 110px;
}
.logo-wrapper {
  align-items: center;
  display: flex;
  height: 150px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvZ28uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSw2QkFBQTtFQUNBLGNBQUE7RUFDQSxhQUFBO0VBQ0EsbUJBQUE7RUFDQSxtQkFBQTtFQUNBLFlBQUE7QUFDRjtBQUNFO0VBQ0UsbUJBQUE7RUFDQSxhQUFBO0VBQ0EsYUFBQTtBQUNKIiwiZmlsZSI6ImxvZ28uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubG9nbyB7XG4gIGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50O1xuICBkaXNwbGF5OiBibG9jaztcbiAgaGVpZ2h0OiAxMTBweDtcbiAgbWFyZ2luOiBhdXRvIGF1dG8gMDtcbiAgb2JqZWN0LWZpdDogY29udGFpbjtcbiAgd2lkdGg6IDExMHB4O1xuXG4gICYtd3JhcHBlciB7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGhlaWdodDogMTUwcHg7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/logo/logo.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;EACA,aAAA;EACA,aAAA;AACJ;AACA,4qBAA4qB","sourcesContent":[".logo {\n  background-color: transparent;\n  display: block;\n  height: 110px;\n  margin: auto auto 0;\n  object-fit: contain;\n  width: 110px;\n\n  &-wrapper {\n    align-items: center;\n    display: flex;\n    height: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
