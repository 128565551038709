// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.content .item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.938rem;
  font-weight: 500;
  width: calc(50% - 2.5px);
}
.content .item .name {
  opacity: 0.6;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhci1kYXRhLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLFFBQUE7RUFDQSxlQUFBO0FBQ0Y7QUFDRTtFQUNFLGFBQUE7RUFDQSxzQkFBQTtFQUNBLFFBQUE7RUFDQSxtQkFBQTtFQUNBLGdCQUFBO0VBQ0Esd0JBQUE7QUFDSjtBQUNJO0VBQ0UsWUFBQTtBQUNOIiwiZmlsZSI6ImNhci1kYXRhLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbnRlbnQge1xuICBkaXNwbGF5OiBmbGV4O1xuICBnYXA6IDVweDtcbiAgZmxleC13cmFwOiB3cmFwO1xuXG4gIC5pdGVtIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiA1cHg7XG4gICAgZm9udC1zaXplOiAwLjkzOHJlbTtcbiAgICBmb250LXdlaWdodDogNTAwO1xuICAgIHdpZHRoOiBjYWxjKDUwJSAtIDIuNXB4KTtcblxuICAgIC5uYW1lIHtcbiAgICAgIG9wYWNpdHk6IDAuNjtcbiAgICB9XG4gIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/car-data/car-data.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;EACA,gBAAA;EACA,wBAAA;AACJ;AACI;EACE,YAAA;AACN;AACA,4vBAA4vB","sourcesContent":[".content {\n  display: flex;\n  gap: 5px;\n  flex-wrap: wrap;\n\n  .item {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n    font-size: 0.938rem;\n    font-weight: 500;\n    width: calc(50% - 2.5px);\n\n    .name {\n      opacity: 0.6;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
